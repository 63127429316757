<script setup lang="ts">
import { getTranslatedProperty, getProductUrl } from "@shopware/helpers";
import { calculateSliderOverflow } from "@/helpers/calculateSliderOverflow";
import { mapToKippieButtonColor } from "@/helpers/color";
import type { AssetStoryblok, CarouselStoryblok } from "~/types/storyblok";
import { SwiperSlide } from "swiper/vue";
import type { Schemas } from "#shopware";
import type { Icon, Justify, ButtonSize } from "@kippie/ui";
import { formatPrice } from "~/helpers/formatters";
import { transformUrl } from "@/helpers/link-transformers";
import { getThumbnailUrl } from "~/helpers/mediaHelpers";

type StoryblokProduct = Schemas["Product"] & {
	button: string;
	image?: AssetStoryblok;
};

const props = defineProps<{ blok: CarouselStoryblok }>();

const { width } = useWindowSize();
const { apiClient } = useShopwareContext();
const { products: recentlyViewedProductIds } = useRecentlyViewed();

const slideOffset = ref<number>(24);

let ids = props.blok.items.map(({ id }) => id);
if (props.blok.dynamicItems === "recentlyViewed") {
	ids = recentlyViewedProductIds.value?.slice(0, 10);
}

const { data, refresh } = await useLazyAsyncData(
	`carousel-products:${props.blok._uid}`,
	async () => {
		const { data } = await apiClient.invoke("readProduct post /product", {
			body: {
				filter: [
					{
						type: "equalsAny",
						field: "id",
						value: ids.join("|")
					},
					{
						type: "equals",
						field: "active",
						value: true
					}
				],
				associations: {
					crossSellings: {
						limit: 1,
						filter: [
							{
								type: "equals",
								field: "active",
								value: true
							}
						]
					}
				},
				includes: {
					product: [
						"id",
						"name",
						"customFields",
						"calculatedPrice",
						"seoUrls",
						"cover",
						"calculatedListingPrice",
						"crossSellings"
					],
					product_cross_selling: ["id", "name", "translated.name"]
				},
				limit: ids.length
			}
		});
		return data;
	},
	{
		server: false
	}
);

if (props.blok.dynamicItems === "recentlyViewed") {
	watch(recentlyViewedProductIds, () => {
		ids = recentlyViewedProductIds.value;
		refresh();
	});
}

const orderProducts = (products: StoryblokProduct[] = [], ordering: string[] = [], filterOut = false) => {
	const productsWithOrder = products.filter((product) => ordering.includes(product.id)) ?? [];
	const productsWithoutOrder = products.filter((product) => !ordering.includes(product.id)) ?? [];

	const orderedProducts = ordering.map((id: string) => productsWithOrder.find((product) => product.id === id));

	// if product exists in ordering, but not in response, it will be undefined
	const orderedProductsWithoutUndefined =
		orderedProducts.filter((product: StoryblokProduct | undefined) => product) ?? [];

	return [...orderedProductsWithoutUndefined, ...(filterOut ? [] : productsWithoutOrder)];
};

const products = computed<StoryblokProduct[]>(() => {
	const mappedElements =
		data.value?.elements?.map((product) => {
			const sbProduct = props.blok.items.find((item) => item.id === product.id);
			return {
				...product,
				button: "Voeg toe",
				...(sbProduct
					? {
							button: sbProduct.button,
							image: sbProduct.image,
							sbProduct: !!sbProduct
						}
					: {})
			};
		}) ?? [];

	if (props.blok.dynamicItems === "recentlyViewed") {
		return orderProducts(mappedElements, recentlyViewedProductIds.value, true);
	}

	return orderProducts(
		mappedElements,
		props.blok.items.map(({ id }) => id)
	);
});

if (props.blok.container) {
	watch(width, (v) => (slideOffset.value = calculateSliderOverflow(v)), { immediate: true });
}

const isTeaserCard = computed(() => props.blok.cardType === "product-teaser-card");
</script>

<template>
	<div
		v-if="!blok.dynamicItems || products.length"
		v-editable="blok"
		class="py-10 space-y-8"
		:class="[
			blok.backgroundColor === 'white' ? 'md:bg-white' : 'md:bg-sand',
			(blok.backgroundColorMobile || blok.backgroundColor) === 'white' ? 'bg-white' : 'bg-sand'
		]"
	>
		<div class="container flex flex-col lg:flex-row justify-between lg:items-center gap-4">
			<h2 class="text-3xl lg:text-4xl font-brush">
				{{ blok.title }}
			</h2>
			<NuxtLink
				v-if="blok.buttonLink && blok.button?.length"
				:to="transformUrl(blok.buttonLink?.cached_url)"
				class="w-fit"
			>
				<KippieButton
					:color="mapToKippieButtonColor(blok.button[0].color.color)"
					:pill="blok.button[0].pill"
					:block="blok.button[0].block"
					:shadow="blok.button[0].shadow"
					:justify="blok.button[0].justify as Justify"
					:size="blok.button[0].size as ButtonSize"
					fake
				>
					<template #left v-if="blok.button[0].prefixIcon">
						<SvgIcon :name="blok.button[0].prefixIcon as Icon" />
					</template>
					{{ blok.button[0].label }}
					<template #right v-if="blok.button[0].suffixIcon">
						<SvgIcon :name="blok.button[0].suffixIcon as Icon" />
					</template>
				</KippieButton>
			</NuxtLink>
		</div>
		<div :class="{ container: blok.container }">
			<KippieCarousel
				:space="+(blok.space || 0)"
				:speed="+(blok.speed || 0)"
				:auto-play-speed="+(blok.speed || 0)"
				:centered="blok.centered"
				:loop="blok.loop"
				:navigation="blok.navigation"
				:auto-play="blok.autoPlay"
				:pagination="blok.pagination"
				:pagination-type="blok.paginationType"
				:slide-offset="blok.container ? 0 : slideOffset"
			>
				<SwiperSlide
					v-for="product in products"
					:key="product.id"
					class="max-w-[280px]"
					:style="{ 'margin-right': `${+(blok.space || 0)}px` }"
				>
					<NuxtLink :to="getProductUrl(product)">
						<component :is="`kippie-${blok.cardType}`" hide-favourite class="h-full">
							<template #image>
								<NuxtImg
									v-if="isTeaserCard"
									class="w-full h-full"
									:class="[blok.cardType === 'product-card' ? 'object-contain' : 'object-cover']"
									:src="product.image?.filename"
									width="280"
									height="420"
									:quality="90"
									format="webp"
									provider="storyblok"
									:modifiers="{ smart: true, filters: { focal: product.image?.focus } }"
									:alt="getTranslatedProperty(product, 'name')"
									sizes="xl:280px lg:280px md:280px sm:280px xs:280px"
								/>
								<img
									v-else
									class="w-full h-full object-contain"
									:src="product.cover?.media ? getThumbnailUrl(product.cover.media) : ''"
									:alt="getTranslatedProperty(product, 'name')"
								/>
							</template>

							<template v-if="blok.cardType === 'product-card'" #labels>
								<KippieLabel
									v-if="product.customFields?.kippie_product_fields_first_label"
									:label="product.customFields.kippie_product_fields_first_label as string"
								/>
								<KippieLabel
									v-if="product.customFields?.kippie_product_fields_second_label"
									:label="product.customFields.kippie_product_fields_second_label as string"
								/>
							</template>
							<template
								v-if="
									product.customFields?.kippie_product_fields_main_label &&
									blok.cardType === 'product-card'
								"
								#action
							>
								<p class="px-4 py-[5px] rounded-r-full w-fit text-2xs bg-red text-white">
									{{ product.customFields.kippie_product_fields_main_label }}
								</p>
							</template>

							<span class="font-bold" :class="{ 'text-3xl': isTeaserCard, 'text-base': !isTeaserCard }">
								{{ product.name }}
							</span>
							<p
								v-if="
									blok.cardType === 'product-card' &&
									product.customFields?.kippie_product_fields_short_description
								"
								class="text-sm"
							>
								{{ product.customFields.kippie_product_fields_short_description }}
							</p>

							<AddToCartButton :product-prop="product as any" v-slot="{ onAddToCart }">
								<KippieButton block fake justify="space-between" class="mt-4" @click="onAddToCart">
									{{ product.button }}
									<template #right>
										<span class="flex items-center gap-2">
											<div
												class="text-sm font-medium line-through text-gray"
												v-if="product.calculatedPrice.listPrice"
											>
												{{ formatPrice(product.calculatedPrice.listPrice?.price) }}
											</div>
											{{ formatPrice(product.calculatedPrice.unitPrice) }}
										</span>
									</template>
								</KippieButton>
							</AddToCartButton>
						</component>
					</NuxtLink>
				</SwiperSlide>
			</KippieCarousel>
		</div>
	</div>
</template>
